<template>
  <div class="box">
    <div class="header">
      <div class="header-search">
        <van-nav-bar
          title="搜索"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
        >
        </van-nav-bar>
      </div>
      <div class="choose">
        <van-search
          v-model="appSearchName"
          placeholder="请输入关键词"
          @search="onSearch"
          show-action
          shape="round"
        >
          <template #action>
            <div @click="onSearch(appSearchName)">确定</div>
          </template>
        </van-search>
      </div>
    </div>
    <div class="content contentbox">
      <div class="content-search">
        <!-- 搜索列表 -->
        <van-loading color="#1989fa" v-if="loadingV" />
        <van-empty
          description="未找到搜索结果"
          v-if="formConfig.data.length == 0 && !falg && !loadingV"
        />
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :immediate-check="false"
          v-if="formConfig.data.length !== 0"
        >
          <lists
            :formConfig="formConfig"
            :hasUser="false"
            ref="far"
            :falg="1"
          />
        </van-list>
        <!-- 历史记录 -->
        <div class="record-box" v-if="falg && taa.length != 0">
          <div class="record">
            <span class="recordleft">搜索历史</span>
            <div class="recordicon" @click="dele">
              <van-icon name="delete-o" size="13" /><span class="icontext"
                >清除</span
              >
            </div>
          </div>
          <div
            class="record-data"
            v-for="(item, index) in taa"
            :key="index"
            @click="onSearch(item)"
          >
            <span class="datarecord"
              ><van-icon name="underway-o" style="margin-right: 4px" />
              {{ item }}</span
            >
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
export default {
  name: 'gsnProductsChangeApplySearch',
  data() {
    return {
      loadingV: false,
      pageNum: 1,
      pageSize: 10,
      loading: false,
      finished: true,

      falg: true,
      appSearchName: "",
      formConfig: {
        data: [],
        columns: [
          {
            prop: "supplierCode",
            label: "供应商编码",
          },
          {
            prop: "productTypeName",
            label: "供应商类型",
          },
          {
            prop: "productClassificationSign",
            label: "供应商属性",
          },
          {
            prop: "professionalName",
            label: "专业分类",
          },
        ],
        total: 0,
        url: "gsnSupplierApplyDetails",
      },
    };
  },
  created() {

  },
  computed: {
    taa() {
      return this.$store.state.gsnProductsChangeApply
    },
    statusType() {
      return this.$route.query.params.auditFlag
    }
  },
  methods: {
    async getList(o) {
      let params = {
        ...this.$route.query.params,
        pageNum: this.pageNum,
        searchKey: o
      };
      let data;
      if (this.statusType === 2) {
        params.isPhone = "true"
        data = await this.$api.gsn.getListAllSupplierQuery(params);
        data.rows.forEach((item) => {
          if (item.retrieveflag == 1) {
            item.appStatus = 3;
          } else {
            item.appStatus = item.auditflag;
          }
        });
      } else {
        data = await this.$api.gsn.getListAllSupplierApply(params);
        data.rows.forEach((item) => {
          item.appStatus = item.auditflag;
        });
      }
      this.loadingV = false;
      if (data.rows.length == 0) {
        this.falg = false
      } else {
        this.falg = false
      }
      this.formConfig.data = data.rows;
      this.formConfig.data = this.formConfig.data.concat(data.rows);
    },
    dele() {
      this.delete();
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    onRefresh() {
      this.loading = true;
    },
    onSearch(o) {
      if (!o) {
        Toast.fail("请输入查询内容");
      } else {
        this.appSearchName = o;
        this.$store.commit("setGsnProductsChangeApply", o);
        this.getList(o);
      }
    },
    async onLoad() {
      this.pageNum += 1;
      if (this.pageNum >= this.formConfig.total) {
        this.finished = true
        return
      }
      let params = {
        ...this.$route.query.params,
        pageNum: this.pageNum,
        searchKey: this.appSearchName
      };
      let data;
      if (this.statusType === 2) {
        params.isPhone = "true"
        data = await this.$api.gsn.getListAllSupplierQuery(params);
        data.rows.forEach((item) => {
          if (item.retrieveflag == 1) {
            item.appStatus = 3;
          } else {
            item.appStatus = item.auditflag;
          }
        });
      } else {
        data = await this.$api.gsn.getListAllSupplierApply(params);
        data.rows.forEach((item) => {
          item.appStatus = item.auditflag;
        });
      }
      if (data.rows.length == 0) {
        this.finished = true
      }
      this.loading = false;
      this.formConfig.data = this.formConfig.data.concat(data.rows);
    },
    delete() {
      this.$store.commit("delSearch", 'gsnProductsChangeApply')
    },
  },
};
</script>
<style lang="less">
.content-search {
  min-height: 100%;
  margin-top: 66px;
  .record-box {
    padding: 0.3rem;
    .record {
      width: 100%;
      margin-top: 50px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .recordleft {
        color: #8f8f8f;
      }
      .recordicon {
        display: flex;
        align-items: center;

        .icontext {
          color: #2d8aff;
        }
      }
    }
    .record-data {
      font-size: 12px;
      color: #333;
      border: 1px solid rgb(240, 236, 236);
      line-height: 30px;
      padding: 0 0.3rem;
      display: flex;
      align-items: center;
      margin-top: 5px;
      justify-content: space-between;
      .datarecord {
        display: flex;
        align-items: center;
        margin-left: 2px;
      }
    }
  }
}
</style>
